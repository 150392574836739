<header-navbar [activeComponent]="'aboutus'"></header-navbar>
<top-slider [activeSlide]="'aboutus'"></top-slider>

<div class="above-main-content">
    <!--     <div class="content">
            Download App
            <div id="download-app">
                <h3>Download the App:</h3>
                <div class="store-buttons d-flex justify-content-center">
                    <a href="#"><img src="/assets/img/SVG/Download_on_the_App_Store_Badge_US-UK_blk_092917.svg"></a>
                    <a href="#"><img src="/assets/img/SVG/google-play-badge.svg"></a>
                </div>
            </div>
            end Download App
        </div> -->
    <div id="main-content" class="about-us-page">
        <div class="shadow d-none d-lg-block"></div>
        <div class="content">

            <!-- <div class="container"> -->

            <div class="row">

                <div class="col-md-12">
                    <h3 i18n="@@our-management-team">Our Management Team</h3>
                </div>

                <div class="col-md-12 omt-block">

                    <div class="row omt-employees text-left">

                        <div class="col-md-12">

                            <div class="row omt-employees-wrapper">

                                <div class="col-sm-4 omt-item active" data-bio-id="1">

                                    <div class="omt-photo">
                                        <img src="/assets/img/fazil.jpg" alt="Fazıl Taboğlu" title="Fazıl Taboğlu">
                                    </div>

                                    <div class="omt-personal-info d-flex flex-column">

                                        <div class="fio">
                                            <span i18n="@@fazil-taboglu">Fazıl Taboğlu</span>
                                        </div>

                                        <div class="omt-about">
                                            <span i18n="@@chair">Chairman</span>
                                        </div>

                                        <div class="omt-contact-block d-flex justify-content-between mt-auto">

                                            <div class="omt-mail">
                                                <!-- <i class="far fa-envelope"></i> -->
                                            </div>

                                            <div class="omt-button">
                                                <a i18n="@@about-fazil-taboglu" href="#"
                                                    class="buttonDefault button-red">About</a>
                                            </div>

                                        </div>


                                    </div>

                                    <div class="omt-bio-item-mobile d-sm-none col-md-12 active" data-bio-id="1">

                                        <p i18n="@@about-fazil-taboglu-p1">Graduated from Columbia University School of
                                            International Affairs with Master’s degree (MIA) with specialization in
                                            International Finance and Banking, in 1983. Worked for Citibank as Assistant
                                            Manager during 1983-1985, for BNP as Commercial Manager during 1986-1987 and
                                            Samba Bank as Deputy General Manager during 1987-1993.</p>
                                        <p i18n="@@about-fazil-taboglu-p2">Founded Istanbul Factoring Inc. in 1993,
                                            Istanbul Leasing Inc. in 1996 and Taboglu Holding Inc. in 1996 encompassing
                                            six companies including finance, foreign trade and insurance.</p>
                                        <p i18n="@@about-fazil-taboglu-p3">Dissolved all of these companies following
                                            the 2001 financial market crash in Turkey.</p>
                                        <p i18n="@@about-fazil-taboglu-p4">Founded Modern Business Systems Inc. in 2004.
                                            The company was re-named Gonderal Payment Systems Inc pursuant to license
                                            application to Banking Regulatory and Supervisory Agency, in 2015.</p>

                                    </div>
                                </div>



                                <div class="col-sm-4 omt-item" data-bio-id="3">

                                    <div class="omt-photo">
                                        <img src="/assets/img/elif.jpg" alt="Elif Özbay"
                                            title="Elif Özbay">
                                    </div>

                                    <div class="omt-personal-info d-flex flex-column">

                                        <div class="fio">
                                            <span i18n="@@elif-özbay">Elif Özbay</span>
                                        </div>

                                        <div class="omt-about">
                                            <span i18n="@@general-manager-assistant">Board Member and General Manager</span>
                                        </div>

                                        <div class="omt-contact-block d-flex justify-content-between mt-auto">

                                            <div class="omt-mail">
                                                <!-- <i class="far fa-envelope"></i> -->
                                            </div>

                                            <div class="omt-button">
                                                <a i18n="@@about-elif-özbay" href="#"
                                                    class="buttonDefault button-red">About</a>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="omt-bio-item-mobile d-sm-none col-md-12" data-bio-id="3">

                                        <p i18n="@@about-elif-özbay-p1">She graduated from Uludağ University, Faculty of Economics and Administrative Sciences, Department of Economics in 2000.</p>
                                        <p i18n="@@about-elif-özbay-p2">Throughout her career in the fields of banking and payment services, she has taken on responsibilities in various positions related to internal control, risk management, regulatory compliance, product development, and combating money laundering as a specialist and manager.</p>
                                        <p i18n="@@about-elif-özbay-p3">Since 2021, she has served as the Deputy General Manager responsible for Risk Management and Compliance activities at GönderAL Payment Services and has continued in this role, also becoming a Board Member and General Manager since October 2023.</p>
                                    </div>

                                </div>
                                <!-- <div class="col-sm-3 omt-item" data-bio-id="4">

                                    <div class="omt-photo">
                                        <img src="/assets/img/ilker.png" alt="İlker Keçici" title="İlker Keçici">
                                    </div>

                                    <div class="omt-personal-info d-flex flex-column">

                                        <div class="fio">
                                            <span i18n="@@ilker-kecici">İlker Keçici</span>
                                        </div>

                                        <div class="omt-about">
                                            <span i18n="@@general-manager-assistant">Board Member and Deputy General Manager</span>
                                        </div>

                                        <div class="omt-contact-block d-flex justify-content-between mt-auto">

                                            <div class="omt-mail">
                                            </div>

                                            <div class="omt-button">
                                                <a i18n="@@about-ilker-kecici" href="#"
                                                    class="buttonDefault button-red">About</a>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="omt-bio-item-mobile d-sm-none col-md-12" data-bio-id="4">

                                        <p i18n="@@about-ilker-kecici-p1">He graduated from Uludag University, Faculty of Economics and Administrative Sciences, Department of Economics in 2003.</p>
                                        <p i18n="@@about-ilker-kecici-p2">He started his career at Akbank in 2005 as a Retail Banking Sales Assistant Manager. As of 2007, he worked as a Retail and SME Banking Portfolio Assistant Manager at Garanti Bank. He continued his career as a Branch Manager at Denizbank between 2013-2023.</p>
                                        <p i18n="@@about-ilker-kecici-p3">As of 2023, he started to work as Deputy General Manager in charge of Sales and Operations at GönderAL Payment Services A.Ş and was appointed as a Member of the Board of Directors.</p>

                                    </div>

                                </div> -->

                                <div class="col-sm-4 omt-item" data-bio-id="5">

                                    <div class="omt-photo">
                                        <img src="/assets/img/sezai.jpg" alt="Sezai Bekgöz" title="Sezai Bekgöz">
                                    </div>

                                    <div class="omt-personal-info d-flex flex-column">

                                        <div class="fio">
                                            <span i18n="@@sezai-bekgoz">Sezai Bekgöz</span>
                                        </div>

                                        <div class="omt-about">
                                            <span i18n="@@general-manager-independent">Independent Board Member</span>
                                        </div>

                                        <div class="omt-contact-block d-flex justify-content-between mt-auto">

                                            <div class="omt-mail">
                                                <!-- <i class="far fa-envelope"></i> -->
                                            </div>

                                            <div class="omt-button">
                                                <a i18n="@@about-sezai-bekgoz" href="#"
                                                    class="buttonDefault button-red">About</a>
                                            </div>

                                        </div>

                                    </div>

                                    <div class="omt-bio-item-mobile d-sm-none col-md-12" data-bio-id="5">
                                        <p i18n="@@about-sezai-bekgoz-p1">After graduating from Ankara University, Faculty of Political Sciences, Department of Economics, Bekgöz completed his graduate studies at Istanbul University, Faculty of Economics, and completed his doctorate in banking at Marmara University Banking and Insurance Institute. </p>
                                        <p i18n="@@about-sezai-bekgoz-p2">He worked as an Auditor in the Audit Department of the Capital Markets Board between 1983-1992. He served as the Head of the Board of Inspectors at Borsa Istanbul between 1992 and 1998, and as the Executive Vice Chairman for Markets, Risk Management and Member Affairs at Borsa Istanbul between 1998 and 2007. Between 1997 and 2007, he also served as a Member of the Board of Directors at TURKDEX (VOB), Futures and Options Exchange -İzmir, Takasbank Istanbul Settlement and Custody Bank and Central Registry Agency. Between 2007 and 2016, he worked at Takasbank Istanbul Settlement and Custody Bank Inc. as Deputy General Manager responsible for Domestic and International Clearing and Custody Operations, International Relations, and Member Affairs. .</p>
                                        <p i18n="@@about-sezai-bekgoz-p3">Dr. Sezai Bekgöz provides consultancy services at home and abroad and he is an independent member of the board of directors of some listed companies.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div><!-- End Omt employees -->
                    <div class="row omt-bio d-none d-sm-block text-left">

                        <div class="col-md-12">

                            <div class="omt-bio-wrapper">

                                <div class="row">

                                    <div class="omt-bio-item col-md-12 active" data-bio-id="1">

                                        <p i18n="@@about-fazil-taboglu-p1">Graduated from Columbia University School of
                                            International Affairs with Master’s degree (MIA) with specialization in
                                            International Finance and Banking, in 1983. Worked for Citibank as Assistant
                                            Manager during 1983-1985, for BNP as Commercial Manager during 1986-1987 and
                                            Samba Bank as Deputy General Manager during 1987-1993.</p>
                                        <p i18n="@@about-fazil-taboglu-p2">Founded Istanbul Factoring Inc. in 1993,
                                            Istanbul Leasing Inc. in 1996 and Taboglu Holding Inc. in 1996 encompassing
                                            six companies including finance, foreign trade and insurance.</p>
                                        <p i18n="@@about-fazil-taboglu-p3">Dissolved all of these companies following
                                            the 2001 financial market crash in Turkey.</p>
                                        <p i18n="@@about-fazil-taboglu-p4">Founded Modern Business Systems Inc. in 2004.
                                            The company was re-named Gonderal Payment Systems Inc pursuant to license
                                            application to Banking Regulatory and Supervisory Agency, in 2015.</p>


                                    </div>

<!--
                                    <div class="omt-bio-item col-md-12 " data-bio-id="2">

                                        <p i18n="@@about-fazil-taboglu-p1">Graduated from Columbia University School of
                                            International Affairs with Master’s degree (MIA) with specialization in
                                            International Finance and Banking, in 1983. Worked for Citibank as Assistant
                                            Manager during 1983-1985, for BNP as Commercial Manager during 1986-1987 and
                                            Samba Bank as Deputy General Manager during 1987-1993.</p>
                                        <p i18n="@@about-fazil-taboglu-p2">Founded Istanbul Factoring Inc. in 1993,
                                            Istanbul Leasing Inc. in 1996 and Taboglu Holding Inc. in 1996 encompassing
                                            six companies including finance, foreign trade and insurance.</p>
                                        <p i18n="@@about-fazil-taboglu-p3">Dissolved all of these companies following
                                            the 2001 financial market crash in Turkey.</p>
                                        <p i18n="@@about-fazil-taboglu-p4">Founded Modern Business Systems Inc. in 2004.
                                            The company was re-named Gonderal Payment Systems Inc pursuant to license
                                            application to Banking Regulatory and Supervisory Agency, in 2015.</p>

                                    </div> -->

                                    <!-- <div class="omt-bio-item col-md-12 " data-bio-id="2">

                                        <p i18n="@@about-funda-taboglu-p1">She4 graduated from Bilkent University,
                                            Faculty of Business Administration in 1994. She started her career at Japan
                                            Tobacco International as a Financial Analyst.</p>
                                        <p i18n="@@about-funda-taboglu-p2">She continued her education at Boston
                                            University, School of Management between 1997 and 1999 and received her MBA
                                            degree in 1999.</p>
                                        <p i18n="@@about-funda-taboglu-p3">Between 1999 and 2010, she worked as Trade
                                            Marketing Specialist at Colgate Palmolive, Marketing Manager at Siemens
                                            Mobile Turkey, Regional Sales Director at Siemens Mobile South European
                                            Region in Italy, Regional Marketing Director at Siemens Mobile Western
                                            European Region in France, Corporate Communications Manager at Microsoft
                                            Turkey, and Trade Marketing Division Head at Turkcell.</p>
                                        <p i18n="@@about-funda-taboglu-p4">Between 2010 and 2015, she opened and
                                            operated Taşhan Alaçatı Boutique Hotel in Alaçatı, İzmir.</p>
                                        <p i18n="@@about-funda-taboglu-p5">Since 2016, she has been serving as the
                                            General Manager and Deputy Chairman of the Board of Directors at GönderAL
                                            Ödeme Hizmetleri A.Ş.</p>

                                    </div> -->
                                    <div class="omt-bio-item col-md-12" data-bio-id="3">
                                        
                                        <p i18n="@@about-elif-özbay-p1">She graduated from Uludağ University, Faculty of Economics and Administrative Sciences, Department of Economics in 2000.</p>
                                        <p i18n="@@about-elif-özbay-p2">Throughout her career in the fields of banking and payment services, she has taken on responsibilities in various positions related to internal control, risk management, regulatory compliance, product development, and combating money laundering as a specialist and manager.</p>
                                        <p i18n="@@about-elif-özbay-p3">Since 2021, she has served as the Deputy General Manager responsible for Risk Management and Compliance activities at GönderAL Payment Services and has continued in this role, also becoming a Board Member and General Manager since October 2023.</p>
                                    </div>

                                    <div class="omt-bio-item col-md-12" data-bio-id="4">

                                        <p i18n="@@about-ilker-kecici-p1">He graduated from Uludag University, Faculty of Economics and Administrative Sciences, Department of Economics in 2003.</p>
                                        <p i18n="@@about-ilker-kecici-p2">He started his career at Akbank in 2005 as a Retail Banking Sales Assistant Manager. As of 2007, he worked as a Retail and SME Banking Portfolio Assistant Manager at Garanti Bank. He continued his career as a Branch Manager at Denizbank between 2013-2023.</p>
                                        <p i18n="@@about-ilker-kecici-p3">As of 2023, he started to work as Deputy General Manager in charge of Sales and Operations at GönderAL Payment Services A.Ş and was appointed as a Member of the Board of Directors.</p>

                                    </div>

                                    <div class="omt-bio-item col-md-12" data-bio-id="5">

                                        <p i18n="@@about-sezai-bekgoz-p1">After graduating from Ankara University, Faculty of Political Sciences, Department of Economics, Bekgöz completed his graduate studies at Istanbul University, Faculty of Economics, and completed his doctorate in banking at Marmara University Banking and Insurance Institute. </p>
                                        <p i18n="@@about-sezai-bekgoz-p2">He worked as an Auditor in the Audit Department of the Capital Markets Board between 1983-1992. He served as the Head of the Board of Inspectors at Borsa Istanbul between 1992 and 1998, and as the Executive Vice Chairman for Markets, Risk Management and Member Affairs at Borsa Istanbul between 1998 and 2007. Between 1997 and 2007, he also served as a Member of the Board of Directors at TURKDEX (VOB), Futures and Options Exchange -İzmir, Takasbank Istanbul Settlement and Custody Bank and Central Registry Agency. Between 2007 and 2016, he worked at Takasbank Istanbul Settlement and Custody Bank Inc. as Deputy General Manager responsible for Domestic and International Clearing and Custody Operations, International Relations, and Member Affairs.</p>
                                        <p i18n="@@about-sezai-bekgoz-p3">Dr. Sezai Bekgöz provides consultancy services at home and abroad and he is an independent member of the board of directors of some listed companies.</p>

                                    </div>

                                </div>

                            </div>

                        </div>


                    </div><!-- End Omt Bio -->

                </div>
            </div>

            <div class="row">

                <div class="col-md-12">
                    <h3 i18n="@@share-of-partnernship">Shareholder Structure</h3>
                </div>

                <div class="col-md-8 mx-auto">
                    <table>
                        <tr>
                            <th></th>
                            <th i18n="@@share-table" class="tdColumn">Share</th>
                        </tr>
                        <tr>
                            <td>Fazıl Ahmet Taboğlu</td>
                            <td class="tdColumn">80,82 %</td>
                        </tr>
                        <tr>
                            <td>Hatice Fisun Ulu</td>
                            <td class="tdColumn">9,46 %</td>
                        </tr>
                        <tr>
                            <td>Esin Taboğlu</td>
                            <td class="tdColumn">5,57 %</td>
                        </tr>
                        <tr>
                            <td>Serkan İsmailoğlu</td>
                            <td class="tdColumn">3,73 %</td>
                        </tr>
                        <tr>
                            <td>Diğer</td>
                            <td class="tdColumn">0,42 %</td>
                        </tr>
                        <tr>
                            <td><b>Toplam</b></td>
                            <td class="tdColumn"><b>100 %</b> </td>
                        </tr>

                    </table>
                </div>
            </div><!-- End Partnership -->

            <br>
            <br>
            <div class="row regulatory-block">

                <div class="col-md-12">
                    <h3 i18n="@@regulatory-supervision">Regulatory Supervision</h3>
                </div>

                <div class="col-md-8 mx-auto text-center">

                    <p i18n="@@brsa-desc">Central Bank of the Republic of Turkey<br>
                        Hacı Bayram Mah. İstiklal Cad. No:10 06050 Ulus Altındağ/Ankara 0 (312) 507 5640</p><br>
                    <p i18n="@@slide-leading-corporations"> We're a licensed payment institution under Law 6493 </p>

                </div>

            </div><!-- End Regulatory -->

            <div class="row partners-logos">

                <div class="col-md-12 d-flex flex-wrap justify-content-between align-items-center">
                    <img src="/assets/img/tcmb-yetkili-kurulus.png">
                    <img src="/assets/img/gonderal.png">
                    <img src="/assets/img/todeb.png">
                </div>

            </div> <!-- End Parners Logo -->

            <!-- </div>   End Container -->



        </div><!-- End Content -->
    </div><!-- End Main Content -->
</div>
<footer-template></footer-template>
